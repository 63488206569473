.page-footer {
	@mixin page-section;

	margin: 7rem auto 0;
	width: 100%;
	color: #ccc;
	font-size: 1.6rem;

	&.page-footer--project-page {
		max-width: var(--projectPageWidth);
	}

	.page-footer__social-link {
		display: inline-block;
		transform: scale(0.9);
		width: 2.5rem;
		margin-right: 0.5rem;

		&:first-child {
			margin-left: -0.2rem;
		}
	}
}

.company-info {
	color: inherit;
	font-size: 1.1rem;
	font-weight: 100;
}
