.project__hero {
	width: 100%;
}

.project-page-main {
	@mixin page-section;

	max-width: var(--projectPageWidth);
	margin-top: 4rem;

	@media (width > 800px) {
		margin-top: 6rem;
	}

	.project__key-info {
		font-size: 2rem;
		line-height: 1.2;
		margin-top: 3rem;
		margin-bottom: 3rem;

		.project__title {
			font-size: 1.8em;
			line-height: 1;
			margin: 0.2em 0;
			letter-spacing: 0;
		}

		.project__role {
			font-size: 1.2em;
			font-weight: 500;
			margin: 0;
			letter-spacing: 0;
		}

		.project__links {
			font-size: 1em;
			margin: 0.6rem 0 0;

			a {
				color: var(--brandColor);
			}
		}
	}

	.project__thumbnail-row {
		margin-bottom: 2rem;
	}

	.intro {
		margin-top: 10rem;
	}
}

.project__details {
	margin-top: 2rem;
	font-size: 2rem;
	line-height: 1.4;

	p,
	ul {
		margin-top: 1rem;
	}

	blockquote {
		font-style: italic;
		margin: 4rem auto 0;

		cite {
			display: block;
		}

		@media (width > 600px) {
			max-width: 80%;
		}
	}

	.project__details__section-heading {
		font-size: inherit;
		margin: 3rem 0 1rem;
		line-height: inherit;
	}

	.project__image-container {
		margin-top: 4rem;
	}

	.project__image {
		width: 100%;
		display: block;
	}

	.project__image-wrapper {
		margin: 0 0 2rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.project__image-container--row {
		display: flex;
		width: 100%;
		flex-wrap: wrap;

		.project__image-wrapper {
			flex: 0 1 100%;
		}

		@media (width > 600px) {
			flex-wrap: nowrap;

			.project__image-wrapper {
				margin: 1rem 1rem 0 0;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}

.project__footer {
	@mixin page-section;

	max-width: var(--projectPageWidth);
	text-align: center;
	margin: 9rem auto;
}

.project__view-more-projects {
	font-size: 2.4rem;
	font-weight: 400;
	color: var(--brandColor);
}
