:root {
	--projectGridMargin: 10px;
	--smallBreakpoint: 500px;
}

.project-grid {
	@mixin page-section;

	margin-top: 0.5rem;

	@media (width >= 600px) {
		margin-top: 1rem;
	}

	@media (width >= 1024px) {
		margin-top: 2rem;
	}

	& > *:first-child {
		margin-top: 0;
	}
}

.project-grid__image-container {
	flex: 0 1 33%;
}

.project-grid__image {
	margin: 0;
	width: 100%;
}

.project {
	position: relative;
	margin-bottom: var(--projectGridMargin);
}

.project__title {
	margin: 0 0 0.4rem;
}

.project__link {
	display: block;
	position: relative;
}

.project__summary__close {
	display: none;
	font-size: 2em;
}

.project.project--open {
	.project__summary,
	.project__summary__content {
		opacity: 0;
	}

	.project__link {
		cursor: default;
	}

	.project__summary__close {
		display: initial;
	}

	.project__info-row {
		display: block;
	}
}

.project__link:hover {
	.project__summary__content {
		transform: translate(-50%, -50%);
	}

	.project:not(.project--open) & .project__summary {
		opacity: 1;
		transition-timing-function: ease-in;
	}
}

.project__summary {
	color: white;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(51, 51, 51, 0.6);
	text-align: center;
	font-size: 1.4rem;
	transition: opacity 250ms ease-out;
	will-change: opacity;
	opacity: 0;

	.project__summary__content {
		padding: 0.5rem;
		width: 90%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@media (width >= 650px) {
		font-size: 1.6rem;
	}

	@media (width >= 750px) {
		font-size: 1.8rem;
	}

	@media (width >= 1200px) {
		font-size: 2rem;
	}

	.project__summary__title {
		font-size: 1.36em;
		line-height: 1.25;
		font-weight: 400;
		letter-spacing: 0.025em;
	}

	.project__summmary__readmore {
		font-size: 0.9em;
	}

	.project__summary__title,
	.project__summmary__readmore {
		margin: 0.3em 0;
	}
}

.project__summary .project__summary__content {
	transition: transform 250ms ease-out;
	transform: translate(-50%, -40%);
}

/*
	Need a longer term solution to this really.
 */
.project-grid #digital-garage .project__thumbnail--secondary {
	margin-right: 0;

	@media (width >= 500px) {
		margin-right: var(--projectGridMargin);
	}
}

.project__thumbnail-row {
	width: 100%;
	display: flex;
	flex-flow: row nowrap;

	.project__thumbnail {
		margin: 0 var(--projectGridMargin) 0 0;

		&:last-child {
			margin-right: 0;
		}

		img {
			max-width: 100%;
			width: 100%;
			display: block;
		}

		flex-basis: 100%;

		&.project__thumbnail--primary {
			margin-right: 0;

			@media (width >= 500px) {
				margin-right: var(--projectGridMargin);

				&:last-child {
					margin-right: 0;
				}
			}
		}

		&.project__thumbnail--secondary {
			@media (width >= 400px) and (width <= 500px) {
				margin-left: var(--projectGridMargin);
			}
		}
	}
}

.project-grid .project__thumbnail {
	&:not(.project__thumbnail--primary) {
		display: none;

		@media (width >= 400px) {
			display: block;
		}
	}

	&:not(.project__thumbnail--primary, .project__thumbnail--secondary) {
		display: none;

		@media (width >= 500px) {
			display: block;
		}
	}
}
