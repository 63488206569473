.about {
	& > *:first-child {
		margin-top: 0;
	}

	.page-section__title {
		margin-bottom: 0.5em;
	}

	.about__role__intro {
		font-size: 1.2em;
		margin: 0.5em auto;
	}
}

.skills-row {
	columns: 1;

	@media (width >= 750px) {
		columns: 1;
	}

	.skills-column {
		break-inside: avoid-column;
		max-width: 500px;
	}
}
