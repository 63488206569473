:root {
	--siteMaxWidth: 1080px;
	--projectPageWidth: 800px;
	--bodyMargin: 2rem;
	--brandColor: #fb4d42;
}

@define-mixin page-section {
	width: 100%;
	margin: 0 auto;
	max-width: var(--siteMaxWidth);
}
