

.hero {
	@mixin page-section;

	padding: 0;
	font-size: 2rem;
	margin: 2rem auto 3rem;

	@media (width >= 500px) {
		font-size: 2.2rem;
	}

	@media (width >= 900px) {
		font-size: 2.4rem;
		margin-bottom: 3rem;
	}

	@media (width >= 1150px) {
		margin-top: 3rem;
	}

	@media (width >= 1200px) {
		margin: 6rem auto 4rem;
	}

	.hero__name {
		font-size: 2em;
		line-height: 1;
		font-weight: 400;
		letter-spacing: -0.025em;
		margin: 0 0 0.2em;

		a {
			text-decoration: none;
		}
	}

	.hero__role {
		margin: 0;
		font-size: 1.2em;
		letter-spacing: 0;
		font-weight: 400;
		max-width: 80%;

		@media (width >= 500px) {
			font-size: 1.35em;
		}

		@media (width >= 600px) {
			max-width: auto;
		}
	}

	.hero__nz {
		font-size: 0.95em;
		max-width: 24em;
		line-height: 1.4;
		margin: 0.6em 0 4.5vw;

		@media (width >= 500px) {
			font-size: inherit;
		}

		@media (width >= 1200px) {
			margin-bottom: 4rem;
		}
	}
}

.intro__experience {
	max-width: 850px;

	@media (width >= 800px) {
		width: 80%;
	}
}

.intro__experience,
.intro__links {
	font-size: 0.95em;
	line-height: 1.4;

	@media (width >= 500px) {
		font-size: 0.835em;
	}
}
