:root {
	--transitionDuration: 750ms;
}

.main-nav {
	display: initial;
	will-change: opacity, top;
	transition:
		top var(--transitionDuration) ease-out,
		opacity var(--transitionDuration) ease-out;
	position: fixed;
	top: -100px;
	left: 0;
	opacity: 0;
	width: 100%;
	z-index: 1;
	padding: 1rem 1.7rem;
	background-color: #333;
	color: white;
	font-size: 1.5rem;
	line-height: 1.4;

	&.show {
		transition:
			top var(--transitionDuration) ease-in,
			opacity var(--transitionDuration) ease-in;
		opacity: 1;
		top: 0;
	}

	.main-nav__name-heading {
		font-size: inherit;
		font-weight: inherit;
		display: inline;
	}

	.main-nav__home {
		text-decoration: none;
	}

	&:not(.main-nav--project-page) {
		@media (width < 600px) {
			display: none;
		}
	}

	&.main-nav--project-page {
		opacity: 1;
		top: 0;
	}
}

@media (width <= 800px) {
	.main-nav--project-page {
		display: block;
		position: static;
		width: calc(100% + (var(--bodyMargin) * 2));
		margin: -var(--bodyMargin);
	}
}
