/*
	From CSS Tricks
	https://css-tricks.com/NetMag/FluidWidthVideo/Article-FluidWidthVideo.php

 */
.video-wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}

.video-wrapper {
	iframe,
	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
