@import 'mixins-and-vars.css';

/* Utils */
@import '../../node_modules/normalize.css/normalize.css';
@import 'font-awesome-4.7.0/css/font-awesome.css';
@import 'utils/box-sizing.css';
@import 'utils/responsive-video-wrapper.css';

/* Project components */
@import 'header.css';
@import 'footer.css';
@import 'about.css';
@import 'nav.css';
@import 'project-grid.css';
@import 'project-page.css';

.homepage *, .hero * {
	font-family: 'Cardo', serif;
}

* {
	font-family: 'Karla', serif;
	font-weight: 400;
	font-display: fallback;
}

html {
	font-size: 62.5%;
	line-height: 1;
	
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
i,
span {
	&::selection {
		color: var(--brandColor);
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 600;
	letter-spacing: 0.05em;
}

body {
	background-color: white;
	color: #333;
	margin: var(--bodyMargin);
}

a {
	color: inherit;
	will-change: color;
	transition: color 250ms;

	&:hover,
	&:active {
		color: var(--brandColor);
	}

	overflow-wrap: break-word;
	word-wrap: break-word;
}


.homepage.hero {
	
	max-width: 700px;
	margin-top: 4rem;
	
	.coffee-dude {
		display: block;
		width: 8em;
	}
	
	@media (width >= 800px) {
		font-size: 2.4rem;
		margin: 6rem auto 4rem;
	}

	
	.intro__experience {
		max-width: 850px;

		@media (width >= 800px) {
			width: 100%;
		}
	}
}